import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  FooterLinksContainer, // Ensure this is defined in your styles
  CopyrightContainer,   // Add this for copyright and links container
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            key={src}
            aria-label={src}
        >
          <SvgIcon src={src} width="25px" height="25px" />
        </a>
    );
  };

  return (
      <>
        <FooterSection>
          <Container>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Language>{t("Contact")}</Language>
                <Large to="/">{t("Tell us everything")}</Large>
                <Para>
                  {t(`Do you have any question? Feel free to reach out.`)}
                </Para>
                <a href="mailto:contact@ubeldi.com">
                  <Chat>{t(`Let's Chat`)}</Chat>
                </a>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Title>{t("Policy")}</Title>
                <Large to="/">{t("Application Security")}</Large>
                <Large to="/">{t("Software Principles")}</Large>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Empty />
                <Large to="/">{t("Support Center")}</Large>
                <Large to="/">{t("Customer Support")}</Large>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={12}>
                <Empty />
                <Language>{t("Address")}</Language>
                <Para>62, Rue de Chatillon</Para>
                <Para>92140 Clamart</Para>
                <Para>IDF, France</Para>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Title>{t("Company")}</Title>
                <Large to="/about">{t("About")}</Large>
                <Large to="/careers/">{t("Careers & Culture")}</Large>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Label htmlFor="select-lang">{t("Language")}</Label>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                        src="united-states.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("es")}>
                    <SvgIcon
                        src="spain.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("ar")}>
                    <SvgIcon
                        src="morocco.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("fr")}>
                    <SvgIcon
                        src="france.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
            </Row>
          </Container>
        </FooterSection>
        <Extra>
          <Container border={true}>
            <Row
                justify="space-between"
                align="middle"
                style={{ paddingTop: "3rem" }}
            >
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                      src="logo.svg"
                      aria-label="homepage"
                      width="101px"
                      height="64px"
                  />
                </LogoContainer>
              </NavLink>
              <FooterContainer>
                <SocialLink
                    href="https://facebook.com/Ubeldi"
                    src="facebook.svg"
                />
                <SocialLink
                    href="https://x.com/ubeldi"
                    src="twitter.svg"
                />
                <SocialLink
                    href="https://www.linkedin.com/company/ubeldi/"
                    src="linkedin.svg"
                />
              </FooterContainer>
            </Row>
            <Row justify="start" style={{ paddingTop: "1rem" }}>
              <CopyrightContainer>
                <Para>© {new Date().getFullYear()} Ubeldi. All rights reserved.</Para>
                <FooterLinksContainer>
                  <a href="/legal">{t("Legal")}</a> •
                  <a href="/Privacy-policy">{t("Privacy")}</a> •
                  <a href="/accessibility">{t("Accessibility")}</a> •
                  <a href="/cookie-policy">{t("Cookie Policy")}</a> •
                  <a href="/legal-information">{t("Legal Information")}</a> •
                  <a href="/terms-of-use">{t("Terms of Use")}</a> •
                  <a href="/contact">{t("Contact")}</a>
                </FooterLinksContainer>
              </CopyrightContainer>
            </Row>
          </Container>
        </Extra>
      </>
  );
};

export default withTranslation()(Footer);
