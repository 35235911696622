const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/about"],
    exact: true,
    component: "About",
  },
  {
    path: ["/", "/invest"],
    exact: true,
    component: "Invest",
  },
  {
    path: ["/", "/careers"],
    exact: true,
    component: "Careers",
  },
  {
    path: ["/", "/privacy-policy"],
    exact: true,
    component: "Privacy-policy",
  }
];

export default routes;
