import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { useHistory } from "react-router-dom"; // Use useHistory for v5

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  LanguageSwitchContainer,
  LanguageSwitch,
} from "./styles";
import i18n from "i18next";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory(); // Use history instead of navigate

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
    setVisibility(false);
  };

  const MenuItem = () => (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => history.push("/invest")}>
          <Span>{t("Invest")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => history.push("/careers")}>
          <Span>{t("Careers")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ width: "180px" }} onClick={() => scrollTo("contact")}>
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
  );

  return (
      <HeaderSection>
        <Container>
          <Row justify="space-between">
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="logo.svg" width="101px" height="64px" />
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            <Burger onClick={toggleButton}>
              <Outline />
            </Burger>
          </Row>
          <Drawer closable={false} open={visible} onClose={toggleButton}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <Label onClick={toggleButton}>
                <Col span={12}>
                  <Menu>Menu</Menu>
                </Col>
                <Col span={12}>
                  <Outline />
                </Col>
              </Label>
            </Col>
            <MenuItem />
            <Row justify="center" style={{ marginTop: "1rem" }}>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon src="united-states.svg" aria-label="English" width="30px" height="30px" />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon src="spain.svg" aria-label="Spanish" width="30px" height="30px" />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("ar")}>
                  <SvgIcon src="morocco.svg" aria-label="Arabic" width="30px" height="30px" />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("fr")}>
                  <SvgIcon src="france.svg" aria-label="French" width="30px" height="30px" />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Row>
          </Drawer>
        </Container>
      </HeaderSection>
  );
};

export default withTranslation()(Header);
